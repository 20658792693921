//    getTheTokensOfOwner()
import {
  WalletAdapterNetwork,
  WalletNotConnectedError,
} from "@solana/wallet-adapter-base";
import {
  ConnectionProvider,
  WalletProvider,
  useConnection,
  useWallet,
} from "@solana/wallet-adapter-react";
import {
  WalletModalProvider,
  WalletMultiButton,
} from "@solana/wallet-adapter-react-ui";
import {
  GlowWalletAdapter,
  PhantomWalletAdapter,
  SlopeWalletAdapter,
  SolflareWalletAdapter,
  TorusWalletAdapter,
  LedgerWalletAdapter,
  SolletExtensionWalletAdapter,
  SolletWalletAdapter,
} from "@solana/wallet-adapter-wallets";
import ReactDOM from "react-dom";
import background from "../src/img/MetaLabz_Main_Logo_2400x1800.png";
import {
  clusterApiUrl,
  Connection,
  PublicKey,
  LAMPORTS_PER_SOL,
} from "@solana/web3.js";
import React, { FC, ReactNode, useMemo, useCallback, useState } from "react";

import { Metadata } from "@metaplex-foundation/mpl-token-metadata";
import axios from "axios";
import { TOKEN_PROGRAM_ID } from "@solana/spl-token";
require("./App.css");
require("@solana/wallet-adapter-react-ui/styles.css");

let tokensInWallet: any = [];
let globalTokenNames: any = [];

const App: FC = () => {
  return (
    <Context>
      <Content />
    </Context>
  );
};
export default App;

const Context: FC<{ children: ReactNode }> = ({ children }) => {
  // The network can be set to 'devnet', 'testnet', or 'mainnet-beta'.
  const network = WalletAdapterNetwork.Mainnet;

  // You can also provide a custom RPC endpoint.
  const endpoint = useMemo(() => clusterApiUrl(network), [network]);

  // @solana/wallet-adapter-wallets includes all the adapters but supports tree shaking and lazy loading --
  // Only the wallets you configure here will be compiled into your application, and only the dependencies
  // of wallets that your users connect to will be loaded.
  const wallets = useMemo(
    () => [
      new PhantomWalletAdapter(),
      new GlowWalletAdapter(),
      new SlopeWalletAdapter(),
      new SolflareWalletAdapter({ network }),
      new TorusWalletAdapter(),
      new LedgerWalletAdapter(),
      new SolletExtensionWalletAdapter(),
      new SolletWalletAdapter(),
    ],
    [network]
  );

  return (
    <ConnectionProvider endpoint={endpoint}>
      <WalletProvider wallets={wallets} autoConnect>
        <WalletModalProvider>{children}</WalletModalProvider>
      </WalletProvider>
    </ConnectionProvider>
  );
};

const Content: FC = () => {
  const [bscAddress, setBscAddress] = useState("");
  const [responseMessage, setResponseMessage] = useState("");

  const handleBscAddressChange = (event) => {
    setBscAddress(event.target.value);
  };

  const connection = new Connection(
    "https://long-long-dawn.solana-mainnet.quiknode.pro/5c86906945a110ef52a654679268b165915d037b/"
  );

  //getTokenAccountsByOwner(publicKey,)
  async function getTheTokensOfOwner(MY_WALLET_ADDRESS: string) {
    (async () => {
      // const MY_WALLET_ADDRESS = "CUPmEnnMk42vZPCnHBTdu7eachN2bSe6tYAoB6y5v6Np";
      const connection = new Connection(
        "https://long-long-dawn.solana-mainnet.quiknode.pro/5c86906945a110ef52a654679268b165915d037b/",
        "confirmed"
      );

      const accounts = await connection.getParsedProgramAccounts(
        TOKEN_PROGRAM_ID, // new PublicKey("TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA")
        {
          filters: [
            {
              dataSize: 165, // number of bytes
            },
            {
              memcmp: {
                offset: 32, // number of bytes
                bytes: MY_WALLET_ADDRESS, // base58 encoded string
              },
            },
          ],
        }
      );

      console.log(
        `Found ${accounts.length} token account(s) for wallet ${MY_WALLET_ADDRESS}: `
      );
      let totalNFTsI = 0;
      await accounts.forEach((account, i) => {
        // account.account.data;
        let amountI =
          account.account.data["parsed"]["info"]["tokenAmount"]["uiAmount"];
        let mint_s = account.account.data["parsed"]["info"]["mint"];

        if (amountI == 1) {
          totalNFTsI += 1;

          try {
            console.log(
              `-- Token Account Address ${
                i + 1
              }: ${account.pubkey.toString()} --`
            );
            console.log(`Mint: ${mint_s}`);
            let objT: any = {};
            objT.mint = mint_s;
            objT.amount = amountI;
            tokensInWallet.push(objT);

            // let token_amount_i = account.account.data["parsed"]["info"]["tokenAmount"]["uiAmount"]
            console.log(`Amount: ${amountI}`);
          } catch {
            //tokensInWallet.push({mint:mint_s,amount: amountI })
          }
        }
      });

      console.log("tokens: " + tokensInWallet);
      let currentI = 0;
      await tokensInWallet.forEach((element) => {
        getAccountMetaData(element, element.amount, currentI);
        currentI += 1;
      });

      /*
        // Output
        Found 1 token account(s) for wallet FriELggez2Dy3phZeHHAdpcoEXkKQVkv6tx3zDtCVP8T: 
        -- Token Account Address 1: Et3bNDxe2wP1yE5ao6mMvUByQUHg8nZTndpJNvfKLdCb --
        Mint: BUGuuhPsHpk8YZrL2GctsCtXGneL1gmT5zYb7eMHZDWf
        Amount: 3
      */
    })();
  }
  let elements: any = [];
  let element;

  async function UpdateTheUI(tokenInWallet, number) {
    // Check if the name contains the word "The"
    if (tokenInWallet.name.includes("The")) {
      return fetch(tokenInWallet.uri)
        .then((response) => response.json())
        .then((responseJson) => {
          console.log(responseJson.image);
          let element = <img src={responseJson.image} width="100%" />;
          let elementname = <h1>{tokenInWallet.name}</h1>;
          ReactDOM.render(element, document.getElementById("img" + 0));
          ReactDOM.render(elementname, document.getElementById("tit" + 0));
          elements.push(element);

          return responseJson.image;
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }
  const { publicKey, sendTransaction } = useWallet();

  async function sendtoDb() {
    console.log(globalTokenNames);

    // Define the data to be sent in the request body
    const requestData = {
      Nftname: globalTokenNames,
      soladdress: publicKey?.toBase58(),
      bscaddress: bscAddress,
      claimed: true, // or false based on your requirement
    };

    try {
      // Make the Axios POST request to the server
      const response = await axios.post(
        "https://cyberwebsec.com/45.136.141.140:8089/staking/createStake",
        requestData,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );

      console.log("Response:", response.data);

      setResponseMessage(response.data.message);

      setTimeout(() => {
        setResponseMessage("");
      }, 10000);
      // Handle the response data
    } catch (error) {
      console.error("Error:", error);
      // Handle errors
    }
  }

  async function getAccountMetaData(mint, amountI, numberI) {
    (async () => {
      let mintPubkey = new PublicKey(mint.mint);
      let tokenmetaPubkey = await Metadata.getPDA(mintPubkey);

      const tokenmeta: any = await Metadata.load(connection, tokenmetaPubkey);
      if (tokenmeta.data.data["name"].includes("The")) {
        console.log(tokenmeta.data.data["name"]);
        globalTokenNames.push(tokenmeta.data.data["name"]);

        tokensInWallet[numberI].name = tokenmeta.data.data["name"];
        tokensInWallet[numberI].uri = tokenmeta.data.data["uri"];
        await UpdateTheUI(tokensInWallet[numberI], numberI);
      }
    })();
  }

  const onClick = useCallback(async () => {
    if (!publicKey) throw new WalletNotConnectedError();
    connection.getBalance(publicKey).then((bal) => {
      console.log(bal / LAMPORTS_PER_SOL);
    });

    console.log(publicKey.toBase58());
    getTheTokensOfOwner(publicKey.toBase58());
  }, [publicKey, sendTransaction, connection]);

  return (
    <div className="relative z-10 bg-[url('../src/img/background.jpg')] bg-fixed overflow-hidden pb-20 lg:h-screen">
      <div className=" shadow-xl">
        <div className="container  grid items-center px-4 py-4 mx-auto lg:py-10 lg:grid-cols-7">
          <div className="flex justify-center lg:col-span-2">
            <a
              className="bg-[#ffffff3e] border-2 border-white rounded-full px-4 py-3"
              href="https://tipinu.xyz/"
            >
              BACK TO WEBSITE
            </a>
          </div>

          <div className="flex flex-col items-center justify-center mt-8 lg:col-span-8 lg:mt-0">
            <img className="h-24" src={background} alt="" />
          </div>
        </div>
      </div>
      <div className="relative z-10  bg-fixed bg-cover  lg:h-[115vh]">
        <div className="container px-4 mx-auto text-center lg:px-4 lg:mt-10">
          <h1>TipInu Platform</h1>

          <p className="mt-6 text-md lg:text-lg">
            Claim your solana NFT to get BSC rewards
          </p>
        </div>

        <div className="flex justify-center mt-12 lg:hidden">
          <WalletMultiButton />
        </div>

        <div className="container flex flex-row justify-center items-center  gap-4 px-4 mx-auto mt-8 text-center lg:mt-4 max-w-7xl">
          <div className="hidden mxau lg:col-span-2 lg:block">
            <WalletMultiButton />
          </div>
        </div>

        <div className="container flex flex-row justify-center  items-center gap-10 px-4 mx-auto mt-6  max-w-7xl">
          <div className="h-full px-10 py-8 border-2 border-white bg-[#ffffff3e] shadow-2xl  rounded-2xl">
            <div className="flex flex-col items-center justify-center gap-4 ">
              <div className="flex flex-col gap-6">
                <h4 className="text-2xl font-semibold text-gray-050 text-center">
                  Claim your NFT
                </h4>
                <button className="bg-white p-2 rounded-lg" onClick={onClick}>
                  See available NFT
                </button>
              </div>

              <div className="span4">
                <ul className="thumbnails">
                  <p id="tit0"></p>

                  <li className="span10">
                    <div id="img0" className="thumbnail0 w-64"></div>
                  </li>
                </ul>
              </div>
              <input
                className="w-full p-2 rounded-lg text-black"
                type="text"
                placeholder="BSC address"
                value={bscAddress} // Value is controlled by state
                onChange={handleBscAddressChange} // Handle changes
              />
              <button className="bg-white p-2 rounded-lg" onClick={sendtoDb}>
                Claim BSC rewards
              </button>

              {responseMessage && (
                <div className="text-center text-black">
                  {responseMessage}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
